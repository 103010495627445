import { Button, Col, Divider, Form, Input, Row, Modal, Checkbox } from 'antd'
import React, { useState } from 'react'
import { volunteerMessageForm } from 'validations/volunteerMessageForm'

const SendMessageModal = ({ isOpen, handler, submit }) => {
  const [messageForm] = Form.useForm()

  const [enabledSmsNotification, setEnabledSmsNotification] = useState(true)
  const [enabledEmailNotification, setEnabledEmailNotification] = useState(true)

  const onFinish = (values) => {
    const data = { ...values, enabledEmailNotification, enabledSmsNotification }
    messageForm.resetFields()
    submit(data)
  }
  return (
    <Modal
      title="Send Message"
      visible={isOpen}
      onCancel={() => handler(false)}
      footer={null}
    >
      <Form form={messageForm} layout="vertical" key={0} onFinish={onFinish}>
        <Row className="t-p-1">
          <Col span={24}>
            <Form.Item
              name="subject"
              label="Subject"
              colon={false}
              rules={[volunteerMessageForm]}
            >
              <Input placeholder="Enter Subject" />
            </Form.Item>
            <Form.Item
              name="message"
              label="Message"
              colon={false}
              rules={[volunteerMessageForm]}
            >
              <Input.TextArea
                placeholder="Enter Message ..."
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
            <Checkbox
              onChange={() => setEnabledSmsNotification((prev) => !prev)}
              checked={enabledSmsNotification}
            >
              Send SMS
            </Checkbox>
            <Checkbox
              onChange={() => setEnabledEmailNotification((prev) => !prev)}
              checked={enabledEmailNotification}
            >
              Send Email
            </Checkbox>
            <br />
            <br />
            <p>Note: This invite will be send to selected users only.</p>
          </Col>
          <Divider />
          <Col span={24} className="text-right">
            <Button
              type="secondary"
              className="mx-5"
              onClick={() => handler(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="px-25"
              htmlType="submit"
              disabled={!enabledEmailNotification && !enabledSmsNotification}
            >
              Send
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default SendMessageModal
