import Api, { makeRequest } from 'api/apiv2'
import { sortByDate, sortByString } from 'utils'
import { uploadFile } from 'store/authSlice/authActions'
import { volunteerOpportunityActions } from './volunteerOpportunityReducer'

export const addVolunteerOpportunity = async (data, defaultFyler) => {
  let updatedData = data

  // for flyer file as it can 1 or more than 1
  if (updatedData.bannerImages && updatedData.bannerImages.length) {
    const FlyerFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.bannerImages.length; i += 1) {
      const file = updatedData.bannerImages[i]
      if (!file?.url && !file?.fileName) {
        const flyerFile = await uploadFile(file)
        if (flyerFile && flyerFile?.fileName) {
          FlyerFiles.push({
            fileName: flyerFile?.fileName,
            fileMimeType: flyerFile?.fileMimeType,
          })
        }
      } else {
        const index = file?.uid || i
        if (defaultFyler.length > index) {
          const defaultFile = defaultFyler[index]
          FlyerFiles.push(defaultFile)
        }
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      bannerImages: FlyerFiles,
    }
  }

  const response = await makeRequest(
    'post',
    '/admin/opportunities',
    { organization: updatedData?.organizationId },
    updatedData,
  )
  return response
}

export const updateVolunteerOpportunity = async (id, data, defaultFyler) => {
  let updatedData = data

  // for Fyler file as it can 1 or more than 1
  if (updatedData.bannerImages && updatedData.bannerImages.length) {
    const FlyerFiles = []
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < updatedData.bannerImages.length; i += 1) {
      const file = updatedData.bannerImages[i]
      if (!file?.url && !file?.fileName) {
        const flyerFile = await uploadFile(file)
        if (flyerFile && flyerFile?.fileName) {
          FlyerFiles.push({
            fileName: flyerFile?.fileName,
            fileMimeType: flyerFile?.fileMimeType,
          })
        }
      } else {
        const index = file?.uid || i
        const defaultFile = defaultFyler[index]
        FlyerFiles.push(defaultFile)
      }
    }
    /* eslint-disable no-await-in-loop */
    updatedData = {
      ...updatedData,
      bannerImages: FlyerFiles,
    }
  }

  const response = await makeRequest(
    'put',
    `/admin/opportunities/${id}`,
    { organization: updatedData?.organizationId },
    updatedData,
  )
  return response
}

export const fetchOrganizationVolunteers = (organization) => (dispatch) => {
  Api.get(`/admin/volunteer-community?organizationId=${organization?.id}`)
    .then((res) => {
      dispatch(volunteerOpportunityActions.setVolunteers(res?.data))
    })
    .catch((error) => {
      console.log('error', error)
      dispatch(volunteerOpportunityActions.setVolunteers([]))
    })
}

export const getVolunteerOpportunity = async (id) => {
  const { data } = await makeRequest('get', `/admin/opportunities/${id}`)
  return data
}

export const getVolunteerOpportunityWithStats = async (id) => {
  const { data } = await makeRequest(
    'get',
    `/admin/opportunities/${id}/detail-with-stats`,
  )
  return data
}

export const getOrganizationOpportunities =
  (organizationId, sortKey) => (dispatch) => {
    dispatch(volunteerOpportunityActions.setLoading(true))
    Api.get(`/admin/opportunities?organizationId=${organizationId}`)
      .then((res) => {
        const sortedData =
          sortKey === 'title'
            ? sortByString(res?.data, sortKey)
            : sortByDate(res?.data, sortKey)
        dispatch(volunteerOpportunityActions.setOpportunities(sortedData))
        dispatch(volunteerOpportunityActions.setLoading(false))
      })
      .catch((error) => {
        console.log('error', error)
        dispatch(volunteerOpportunityActions.setOpportunities([]))
        dispatch(volunteerOpportunityActions.setLoading(false))
      })
  }
