import { Auth } from 'aws-amplify'

const awsconfig = {
  region: 'us-east-2',
  paymentHashUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/getpaymenthash',
  signUrl:
    'https://eq8wcmr7jb.execute-api.us-east-2.amazonaws.com/TestStage/presignedurl',
  uploadUrl: 'https://s3.us-east-2.amazonaws.com/pledgegardentest',
  imageUrl: 'https://d2vns28tgp6o27.cloudfront.net/',
  userPoolId: 'us-east-2_1f8YQ1brF',
  userPoolWebClientId: '6ro8i91j19l1fvc42q13jqvltv',
  aws_appsync_graphqlEndpoint:
    'https://oo2nnwzy2fh4znwgsa6cxmha3e.appsync-api.us-east-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-east-2',
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: 'da2-4kiftsd6cfdtrn3lyc72vhbefa',
  oauth: {
    domain: 'dev-pledgarden.auth.us-east-2.amazoncognito.com',
    scope: [
      'phone',
      'email',
      'profile',
      'openid',
      'aws.cognito.signin.user.admin',
    ],
    redirectSignIn: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
    redirectSignOut: 'pledge://', // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
  },
  bucketUrl: 'https://pledge-garden-upload-content.s3.amazonaws.com/',
  API: {
    endpoints: [
      {
        name: 'pg-api-v1',
        api_url: 'https://api.dev.extensia.cloud',
        endpoint: 'https://api.dev.extensia.cloud/v1',
        endpointv2: 'https://api.dev.extensia.cloud/v2',
        // api_url: 'http://localhost:4000', // for local
        // endpoint: 'http://localhost:4000/v1', // for local
        custom_header: async () => ({
          Authorization: `Bearer ${(await Auth.currentSession())
            .getAccessToken()
            .getJwtToken()}`,
          idtoken: `${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        }),
      },
    ],
  },
  stripe_publish_key:
    'pk_test_51M2ctlC5sd7OOJiOKV3kfcpox32EGdZriPPtjXlxow33RTdpzTRDAw4efaqFsBbc12O5ArPvZqADktpGmGc0cOmf005H2qcQC0',
  stripe_connect_client_id: 'ca_MmBMbfa5QvFyXgTqIEx6MDv0GUDcGXpw',
  paypal_client_id:
    'ASv-faUtxg7hGygCzaamSliX4dRE3x6Db2HoP7SOg0cz7y6jDts2o5ZVhttmjWtyJF1HldkWYKLq9Zn9',
  paypal_bncode: 'PledgeGarden_SP_PPCP',
  reCaptchaKey: '6Ld6NXkgAAAAAMLMVyXtaCAI3W28hgSpE8_fEP10',
  public_vapid_key:
    'BGHT_yJc1YI9fMpPVcDKITgRlOdlkQ1or2RZ7ObNKs5Rj9ph1KPGR6Klye31sTPBYk01GE5JZkqh-WcqKKuR-TQ',
  google_api_key: 'AIzaSyAu1COT1oscjAn-Jx8KyfAqwh2fNhrGXag',
  webapp_url: 'https://<org>.donate.dev.extensia.cloud',
  volunteer_webapp_url: 'https://development.d39dmqp7o8jc3x.amplifyapp.com',
}

export { awsconfig }
