import * as Yup from 'yup'

const schema = Yup.object().shape({
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
  users: Yup.array().required('Users are required'),
})

const volunteerMessageForm = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { volunteerMessageForm }
