import React, { useState, useEffect } from 'react'
import {
  Card,
  Form,
  Input,
  Row,
  Col,
  Button,
  Switch,
  message,
  Skeleton,
  DatePicker,
  notification,
  Typography,
  Select,
  Avatar,
  Checkbox,
  Divider,
} from 'antd'
import {
  MinusCircleFilled,
  CheckCircleFilled,
  PlusCircleOutlined,
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { useParams, useHistory } from 'react-router-dom'
import { useAuthSelector } from 'store/authSlice/authReducer'
import moment from 'moment'
import { compareDateTimeWithNow, disabledTime } from 'utils'
import { MultiImageUploader } from 'components/elements/MultiImageUploader'
import { volunteerOpportunityValidation } from 'validations'
import {
  addVolunteerOpportunity,
  fetchOrganizationVolunteers,
  getVolunteerOpportunity,
  updateVolunteerOpportunity,
} from 'store/volunteerOpportunitySlice/volunteerOpportunityActions'
import { useVolunteerOpportunitySelector } from 'store/volunteerOpportunitySlice/volunteerOpportunityReducer'
import { useDispatch } from 'react-redux'

// import ReCAPTCHA from "react-google-recaptcha";

const { TextArea } = Input

const { Option } = Select

const ColorOption = ({ title, value }) => (
  <div style={{ marginLeft: 8 }}>
    <Typography.Text strong>{title}: </Typography.Text>
    {value}
  </div>
)

const colorCodes = [
  {
    value: '#ececec',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#ECECEC' }} />
        <ColorOption title="Silver Gray" value="#ECECEC" />
      </div>
    ),
  },
  {
    value: '#d3d3d3',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#D3D3D3' }} />
        <ColorOption title="Charcoal Gray" value="#D3D3D3" />
      </div>
    ),
  },
  {
    value: '#caf0ff',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#CAF0FF' }} />
        <ColorOption title="Sky Blue" value="#CAF0FF" />
      </div>
    ),
  },
  {
    value: '#d6fff8',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#D6FFF8' }} />
        <ColorOption title="Teal" value="#D6FFF8" />
      </div>
    ),
  },
  {
    value: '#c8ffc8',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#C8FFC8' }} />
        <ColorOption title="Mint Green" value="#C8FFC8" />
      </div>
    ),
  },
  {
    value: '#ffefe1',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#FFEFE1' }} />
        <ColorOption title="Peach" value="#FFEFE1" />
      </div>
    ),
  },
  {
    value: '#fee6c5',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#FEE6C5' }} />
        <ColorOption title="Sand" value="#FEE6C5" />
      </div>
    ),
  },
  {
    value: '#ffdee3',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#FFDEE3' }} />
        <ColorOption title="Baby Pink" value="#FFDEE3" />
      </div>
    ),
  },
  {
    value: '#ffd4d0',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#FFD4D0' }} />
        <ColorOption title="Salmon Pink" value="#FFD4D0" />
      </div>
    ),
  },
  {
    value: '#fedaff',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#FEDAFF' }} />
        <ColorOption title="Lavender" value="#FEDAFF" />
      </div>
    ),
  },
  {
    value: '#fffddb',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#FFFEDB' }} />
        <ColorOption title="Ivory" value="#FFFEDB" />
      </div>
    ),
  },
  {
    value: '#f6ffd1',
    label: (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Avatar style={{ backgroundColor: '#F6FFD1' }} />
        <ColorOption title="Lemon Yellow" value="#F6FFD1" />
      </div>
    ),
  },
]

const initialQuestion = {
  question: '',
  required: true,
}

const initialValues = {
  title: '',
  scheduleDate: null,
  address: '',
  colorCode: colorCodes[0].value,
  maxRegistration: '',
  description: '',
  organizatonId: '',
  organizationName: '',
  createdBy: '',
  bannerImages: [],
  keywords: [],
  enableVolunteerFeedback: true,
  showOnLandingPage: true,
  opportunityType: 'standalone',
  questions: [initialQuestion],
}

const CreateVolunteerOpportunities = () => {
  const params = useParams()
  const history = useHistory()
  const { user, organization } = useAuthSelector()
  const { volunteers } = useVolunteerOpportunitySelector()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [fetching, setFetched] = useState(false)
  const [opportunityDetail, setOpportunityDetail] = useState(null)

  const [orgAddress, setOrgAddress] = useState(false)

  const [requireVolunteerMailingAddress, setRequireVolunteerMailingAddress] =
    useState(true)
  const [allowFeedback, setAllowFeedback] = useState(true)
  const [showOnLandingPage, setShowOnLandingPage] = useState(true)
  const [inviteVolunteers, setInviteVolunteers] = useState(false)
  const [opportunityType] = useState(initialValues.opportunityType)

  const [isDetailCollapsed, setIsDetailCollapsed] = useState(true)
  const [isQuestionsCollapsed, setIsQuestionsCollapsed] = useState(params?.id)
  const [questions, setQuestions] = useState(initialValues.questions)

  // For storing default files
  const [defaultFyler, setDefaultFyler] = useState([])

  const dispatch = useDispatch()

  Form.useWatch('description', form)
  Form.useWatch('scheduleDate', form)

  useEffect(() => {
    const getData = async () => {
      setFetched(true)
      if (params.id) {
        const record = await getVolunteerOpportunity(params.id)
        setOpportunityDetail(record)
        if (record) {
          record.scheduleDate = moment(record.scheduleDate)

          const dummyDefaultFlyer = record.bannerImages
            ? record.bannerImages.length
              ? record.bannerImages
              : [record.bannerImages]
            : []

          setDefaultFyler(dummyDefaultFlyer)

          form.setFieldsValue({
            ...record,
          })
          setAllowFeedback(record?.enableVolunteerFeedback)
          setShowOnLandingPage(record?.showOnLandingPage)
          setRequireVolunteerMailingAddress(
            record?.enableVolunteerMailingAddress,
          )
          setInviteVolunteers(record?.enableVolunteerInvitation || false)
          const vIds = record?.invitedVolunteers?.map((v) => {
            const volunteer = volunteers.find(
              (v2) => v2.phoneNumber === v.volunteerPhoneNumber,
            )
            return volunteer?.iD
          })
          form.setFieldValue('invitedVolunteers', vIds)
        } else {
          message.error('Invalid Opportunity')
          history.push('/settings/create-volunteer-opportunities/')
        }
      }
      setFetched(false)
    }
    getData()
  }, [params.id, history, form, volunteers])

  useEffect(() => {
    if (orgAddress) {
      form.setFieldValue('address', orgAddress ? organization?.Address1 : '')
    }
  }, [orgAddress, organization, form])

  useEffect(() => {
    dispatch(fetchOrganizationVolunteers(organization))
  }, [organization, dispatch])

  const formulateVolunteers = () => {
    const data = []
    form.getFieldValue('invitedVolunteers').map((v) => {
      const d = volunteers.find((vd) => vd.iD === v)
      data.push({
        volunteerName: `${d?.firstName} ${d?.lastName}`,
        volunteerPhoneNumber: d?.phoneNumber,
      })
      return data
    })

    return data
  }

  const onFinish = async (values) => {
    setLoading(true)
    try {
      if (params.id) {
        const name = `${user?.firstname} ${user?.lastname}`
        const data = {
          ...values,
          userName: name,
          userId: user?.id,
          createdBy: user.email,
          organizationId: organization?.id,
          organizationName: organization?.Name,
          organizationBackgroundImage: organization?.BackgroundImage,
          showOnLandingPage,
          invitedVolunteers: inviteVolunteers ? formulateVolunteers() : [],
          enableVolunteerInvitation: inviteVolunteers,
          enableQuestioner:
            questions?.length >= 1 && questions[0]?.question !== '',
          enableVolunteerMailingAddress: requireVolunteerMailingAddress,
          questions,
          enableVolunteerFeedback: allowFeedback,
          opportunityType,
        }

        await updateVolunteerOpportunity(
          opportunityDetail.iD,
          data,
          defaultFyler,
        )
        notification.success({
          message: 'Opportunity Updated',
          description: <div>Opportunity updated successfully.</div>,
          icon: (
            <CheckCircleFilled
              style={{
                color: '#00b894',
              }}
            />
          ),
        })
        setLoading(false)
        history.push('/volunteer-opportunities')
      } else {
        const name = `${user?.firstname} ${user?.lastname}`
        const data = {
          ...values,
          userName: name,
          userId: user?.id,
          createdBy: user.email,
          organizationId: organization?.id,
          organizationName: organization?.Name,
          organizationBackgroundImage: organization?.BackgroundImage,
          showOnLandingPage,
          invitedVolunteers: inviteVolunteers ? formulateVolunteers() : [],
          enableVolunteerInvitation: inviteVolunteers,
          enableQuestioner:
            questions?.length >= 1 && questions[0]?.question !== '',
          enableVolunteerMailingAddress: requireVolunteerMailingAddress,
          questions,
          enableVolunteerFeedback: allowFeedback,
          opportunityType,
        }

        await addVolunteerOpportunity(data, defaultFyler)
        notification.success({
          message: 'Opportunity Created',
          description: <div>Opportunity created successfully.</div>,
          icon: (
            <CheckCircleFilled
              style={{
                color: '#00b894',
              }}
            />
          ),
        })
        setLoading(false)
        form.resetFields()
        history.push('/volunteer-opportunities')
      }
    } catch (error) {
      setLoading(false)
      console.log('Error', error)
      message.error(error?.response?.data?.message || 'Something Went Wrong')
    }
  }

  const buttonText = loading
    ? params.id
      ? 'Updating'
      : 'Creating'
    : params.id
    ? 'Update'
    : 'Create'

  const handleNumberInput = (field, value) => {
    const newValue = value.replaceAll(/,/g, '')
    const val = newValue.replace(/\D/g, '')
    form.setFieldsValue({ [field]: val })
  }

  const handleAddNewQuestion = () => {
    setQuestions((prev) => [...prev, initialQuestion])
  }

  const handleQuestionDelete = (index) => {
    const questionsUpdated = [...questions]
    questionsUpdated.splice(index, 1)
    setQuestions(questionsUpdated)
  }

  const handleQuestionInput = (e, index) => {
    const val = e.target.value
    const updatedQuestions = [...questions]
    updatedQuestions[index].question = val
    setQuestions(updatedQuestions)
  }

  const handleQuestionRequired = (index) => {
    const updatedQuestions = [...questions]
    updatedQuestions[index].required = !updatedQuestions[index].required
    setQuestions(updatedQuestions)
  }

  const validationRules = volunteerOpportunityValidation

  return (
    <Card
      className="header-solid"
      bordered={false}
      title={[
        <>
          <h6 className="mb-0 t-text-2xl">
            {params.id
              ? 'Update Volunteer Opportunity'
              : 'Add Volunteer Opportunity'}
          </h6>
          <p className="font-regular">Enter Information</p>
        </>,
      ]}
    >
      <Skeleton loading={fetching} active paragraph={{ rows: 18 }}>
        <Form
          form={form}
          layout="vertical"
          key={0}
          onFinish={onFinish}
          name="volunteerOpportunity"
          initialValues={initialValues}
          scrollToFirstError
        >
          <Row gutter={16}>
            <Col xs={24} md={{ span: 12, offset: 6 }}>
              <Card
                title="Opportunity Detail"
                extra={
                  !isDetailCollapsed ? (
                    <PlusCircleOutlined
                      onClick={() => setIsDetailCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  ) : (
                    <MinusCircleFilled
                      onClick={() => setIsDetailCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  )
                }
              >
                <span style={{ display: isDetailCollapsed ? 'block' : 'none' }}>
                  <Row>
                    <Col span={24}>
                      <Form.Item
                        label="Title*"
                        name="title"
                        rules={[validationRules]}
                      >
                        <Input placeholder="Enter title" />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="scheduleDate"
                        label="Schedule*"
                        colon={false}
                        rules={[validationRules]}
                      >
                        <DatePicker
                          format="MM-DD-YYYY hh:mm A"
                          showTime
                          minuteStep={15}
                          className="t-w-full"
                          disabledDate={(d) =>
                            !d || d.isBefore(moment().subtract(1, 'day'))
                          }
                          disabledTime={disabledTime}
                          onChange={(selectedDate) =>
                            !compareDateTimeWithNow(selectedDate, 'before') &&
                            form.setFieldsValue({
                              StartDate: moment(),
                            })
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Max # of Registrations*"
                        name="maxRegistration"
                        rules={[validationRules]}
                      >
                        <Input
                          min={1}
                          inputMode="numeric"
                          style={{ width: '100%' }}
                          placeholder="10"
                          onChange={(e) =>
                            handleNumberInput('maxRegistration', e.target.value)
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Volunteer Duration*"
                        name="volunteerDuration"
                        rules={[validationRules]}
                      >
                        <Input
                          prefix={
                            <p>
                              <b>Hrs.</b>
                            </p>
                          }
                          min={1}
                          inputMode="numeric"
                          style={{ width: '100%' }}
                          placeholder="4"
                          onChange={(e) =>
                            handleNumberInput(
                              'volunteerDuration',
                              e.target.value,
                            )
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={20} md={20}>
                      <Typography.Title level={5}>
                        Requires Volunteer mailing address?
                      </Typography.Title>
                    </Col>
                    <Col xs={4} md={4} className="text-right">
                      <Switch
                        checked={requireVolunteerMailingAddress}
                        onChange={() =>
                          setRequireVolunteerMailingAddress((prev) => !prev)
                        }
                      />
                    </Col>

                    <Col span={24} style={{ marginTop: 10 }}>
                      <Form.Item
                        label="Place of performance*"
                        name="address"
                        rules={[validationRules]}
                      >
                        <Input placeholder="Address" disabled={orgAddress} />
                      </Form.Item>
                    </Col>

                    <Col xs={20} md={20}>
                      <Typography.Title level={5}>
                        Same as my org. address?
                      </Typography.Title>
                    </Col>
                    <Col xs={4} md={4} className="text-right">
                      <Switch
                        checked={orgAddress}
                        onChange={() => setOrgAddress((prev) => !prev)}
                      />
                    </Col>

                    <Col span={24} style={{ marginTop: 10 }}>
                      <Form.Item
                        label="Description"
                        name="description"
                        rules={[validationRules]}
                      >
                        <TextArea placeholder="Enter description" rows={4} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label="Select Volunteer color code"
                        name="colorCode"
                        rules={[validationRules]}
                      >
                        <Select
                          defaultValue={colorCodes[0].value}
                          style={{ width: '100%' }}
                          onChange={() => null}
                          options={colorCodes}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="bannerImages"
                        label="Banner Image*"
                        colon={false}
                        rules={[validationRules]}
                      >
                        <MultiImageUploader
                          ratio={2 / 1}
                          defaultFiles={
                            form.getFieldValue('bannerImages') &&
                            form.getFieldValue('bannerImages')
                          }
                          onFileChange={(files) =>
                            form.setFieldsValue({
                              bannerImages: files,
                            })
                          }
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="keywords"
                        label="Add Keywords"
                        colon={false}
                        rules={[validationRules]}
                      >
                        <Select
                          mode="tags"
                          style={{
                            width: '100%',
                          }}
                          tokenSeparators={[',']}
                          options={[]}
                        />
                      </Form.Item>
                    </Col>

                    <Col xs={20} md={20}>
                      <Typography.Title level={5}>
                        Invite Volunteer
                      </Typography.Title>
                    </Col>
                    <Col xs={4} md={4} className="text-right">
                      <Switch
                        checked={inviteVolunteers}
                        onChange={() => setInviteVolunteers((prev) => !prev)}
                      />
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="invitedVolunteers"
                        label="Search by name"
                        colon={false}
                        rules={[validationRules]}
                      >
                        <Select
                          disabled={!inviteVolunteers}
                          mode="tags"
                          style={{
                            width: '100%',
                          }}
                        >
                          {volunteers?.map((item) => (
                            <Option key={item.iD}>
                              {item.firstName} {item.lastName}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Divider />
                    </Col>

                    <Col xs={20} md={20}>
                      <Typography.Title level={5}>
                        Allow volunteer feedback/rating
                      </Typography.Title>
                    </Col>
                    <Col xs={4} md={4} className="text-right">
                      <Switch
                        checked={allowFeedback}
                        onChange={() => setAllowFeedback((prev) => !prev)}
                      />
                    </Col>

                    <Col md={24} xs={24} style={{ marginTop: 15 }} />
                    <Col xs={20} md={20}>
                      <Typography.Title level={5}>
                        Show on landing page
                      </Typography.Title>
                    </Col>
                    <Col xs={4} md={4} className="text-right">
                      <Switch
                        checked={showOnLandingPage}
                        onChange={() => setShowOnLandingPage((prev) => !prev)}
                      />
                    </Col>
                  </Row>
                </span>
              </Card>

              <Card
                title="Add Questions"
                extra={
                  !isQuestionsCollapsed ? (
                    <PlusCircleOutlined
                      onClick={() => setIsQuestionsCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  ) : (
                    <MinusCircleFilled
                      onClick={() => setIsQuestionsCollapsed((prev) => !prev)}
                      style={{ fontSize: '24px', cursor: 'pointer' }}
                    />
                  )
                }
                className="t-mt-6"
              >
                <span
                  style={{
                    display: isQuestionsCollapsed ? 'block' : 'none',
                  }}
                >
                  {questions.map((q, i) => (
                    <React.Fragment key={i}>
                      <Row align="middle" gutter={16}>
                        <Col flex="auto">
                          <Form.Item label={`${i + 1}. Question`}>
                            <Input
                              placeholder="Enter your question"
                              onChange={(e) => handleQuestionInput(e, i)}
                              value={q?.question}
                            />
                          </Form.Item>
                        </Col>
                        <Col>
                          {questions.length > 1 && (
                            <DeleteOutlined
                              style={{ fontSize: 20, cursor: 'pointer' }}
                              onClick={() => handleQuestionDelete(i)}
                            />
                          )}
                        </Col>
                      </Row>
                      <Row justify="end" align="middle">
                        <Checkbox
                          checked={q?.required}
                          onChange={() => handleQuestionRequired(i)}
                        >
                          Required
                        </Checkbox>
                      </Row>
                      <Divider />
                    </React.Fragment>
                  ))}
                  <Col>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleAddNewQuestion}
                      disabled={questions[questions.length - 1].question === ''}
                    >
                      Add New
                    </Button>
                  </Col>
                </span>
              </Card>

              <Col span={24} className="text-right t-space-x-2 t-mt-6">
                <Button
                  type="ghost"
                  className="px-25"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  type="primary"
                  className="px-25"
                  htmlType="submit"
                >
                  {buttonText}
                </Button>
              </Col>
            </Col>
          </Row>
        </Form>
      </Skeleton>
    </Card>
  )
}
export { CreateVolunteerOpportunities }
