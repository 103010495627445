import { OrganizationsList } from 'screens/main/organizations'
import { Events } from 'screens/main/events'
import { Programs } from 'screens/main/programs'
import { Services } from 'screens/main/services'
import { Volunteer } from 'screens/main/volunteer'
import { RentalFacilities } from 'screens/main/rentalfacilities'

import { Account } from 'screens/main/settings/my-account'
// import { CreateEvent } from 'screens/main/settings/create-event'
import { CreateProgram } from 'screens/main/settings/create-program'
import { CreateService } from 'screens/main/settings/create-service'
import { CreateVolunteer } from 'screens/main/settings/create-volunteer'
import { CreateRoom } from 'screens/main/settings/create-room'
import { MyInvites } from 'screens/main/settings/my-invites'
import { ManageGovDocs } from 'screens/main/settings/manage-gov-docs'
import { PlatformFeedbacks } from 'screens/main/settings/manage-feedbacks'
import { ManageReservationRequests } from 'screens/main/settings/manage-reservation-requests'
import { PledgeModal } from 'screens/main/settings/manage-organization/components/MyDonors/CommunityList/PledgeModal'

// import { ManageOrganization } from 'screens/main/settings/manage-organization'
import {
  MyDonors,
  MyEvents,
  MyOrganization,
  MyReports,
  MyRooms,
  MyReservations,
  MyAssociationrequests,
  MyBusinessesConfiguration,
} from 'screens/main/settings/manage-organization/components'

import { RegisterOrganization } from 'screens/main/settings/register-organization'
import { SetupPayments } from 'screens/main/settings/setup-payment'
import { EventControl } from 'screens/main/events/components/EventControl'
import { EventView } from 'screens/main/events/components/EventView'
import { OrganizationView } from 'screens/main/organizations/donor/components/OrganizationView'
import {
  AddOrEditHardware,
  Campaigns,
  CreateAuction,
  Hardware,
  ManageSubscription,
  Notifications,
  Reports,
  SubscriberLandingPage,
} from 'screens'
import { CampaignView } from 'screens/main/campaigns/components/CampaignView'
import { VolunteerView } from 'screens/main/volunteer/components/VolunteerView'
// import { JoinEvent } from 'screens/main/events/components/JoinEvent'
import { ProgramView } from 'screens/main/programs/components/ProgramView'
import { ServiceView } from 'screens/main/services/components/ServiceView'
import { ContactUsForm } from 'screens/main/settings/contact-us'
import { PageNotFound } from 'screens/main/settings/page-not-found'
import { RentalReservation } from 'screens/main/rentalfacilities/components/RentalReservation'
import { AuctionItemPayment } from 'screens/main/events/components/AuctionItemPayment'
import {
  AnnualCosts,
  FYEarningsForecastReport,
  LastYearEarningsReport,
  PledgeConversionReports,
  YtdEarningReport,
} from 'screens/main/settings/manage-organization/components/MyReports/FinancialReports'
import {
  ActiveDonorsReport,
  DormantDonorsReport,
  MasterDonorListReport,
  Top50DonorsReport,
  YTDAccountDonation,
} from 'screens/main/settings/manage-organization/components/MyReports/DonorReports'
import { RegisterBusiness } from 'screens/main/settings/register-business'
import {
  MyBusiness,
  MyBusinessServiceRequests,
} from 'screens/main/settings/manage-business'
import { BusinessList } from 'screens/main/business'
import { BusinessView } from 'screens/main/business/donor/components/BusinessView'
import { BusinessSetupPayments } from 'screens/main/settings/manage-business/Business-setup-payment'
import { MyCustomers } from 'screens/main/settings/manage-business/MyCustomers'
import { MyEarnings } from 'screens/main/settings/manage-business/MyEarnings'
import { Auction } from 'screens/main/auction'
import { AuctionView } from 'screens/main/auction/components/AuctionView'
import { AuctionControl } from 'screens/main/auction/components/AuctionControl'
import { JoinAuction } from 'screens/main/auction/components/JoinAuction'
import { AddPaymentCategory } from 'screens/main/settings/setup-payment/payment-categories/components'
import { YtdDonationForOrganization } from 'screens/main/reports/components/ReportList/Financial/ytdDonation'
import { MasterCommunity } from 'screens/main/reports/components/ReportList/Financial/masterCommunity'
import { MonthlyRevenueForcast } from 'screens/main/reports/components/ReportList/Financial/monthlyRevenueForcast'
import { YTDEarningReport } from 'screens/main/reports/components/ReportList/Financial/ytdEarnings'
import { YtdProgramAndServiceForOrganization } from 'screens/main/reports/components/ReportList/Financial/ytdProgramAndService'
import { YtdRevenueForOrganization } from 'screens/main/reports/components/ReportList/Financial/ytdRevenue'
import { AssociationInvitation } from 'screens/main/business/AcceptInvitation'
import { ComponentPerformanceForOrganization } from 'screens/main/reports/components/ReportList/Component/componentPerformance'
import { Home } from 'screens/main/home'
import { RecurringDonations } from 'screens/main/reports/components/ReportList/Financial/recurringDonations'
import { Platform } from 'screens/main/reports/components/ReportList/Platform/PlatformReport'
import { ClassRegistration } from 'screens/main/reports/components/ReportList/Community/ClassRegistration'
import { UpdateOrganization } from 'screens/main/settings/update-organization/UpdateOrganization'
import { Fundraisers } from 'screens/main/fundraiser'
import { CreateFundraiser } from 'screens/main/settings/create-fundraiser'
import { TapClickFundraiser } from 'screens/main/settings/create-tapclick-fundraiser'
import { QuickPathFundraiser } from 'screens/main/settings/create-quickpath-fundraiser'
import { UpdateGlobalMarkup } from 'screens/main/organizations/admin/components/UpdateGlobalMarkup'
import { UpdateMarkup } from 'screens/main/organizations/admin/components/UpdateMarkup'
import { FundraiserView } from 'screens/main/fundraiser/components/FundraiserView'
import { FundraiserControl } from 'screens/main/fundraiser/components/FundraiserControl'
import { AddOrEditProfile } from 'screens/main/settings/manage-organization/components/MyOrganization/OrganizationProfiles/addOrEditProfile'
import { JoinFundraiser } from 'screens/main/fundraiser/components/JoinFundraiser'
import { RentalRoomPayment } from 'screens/unAuthenticatedPayments/RentalRoomPayment'
import { config } from 'configs'
import { OrganizationRegistrationV2 } from 'screens/OrganizationRegisterV2/OrganizationRegistrationV2'
import { CreateVolunteerOpportunities } from 'screens/main/settings/create-volunteer-opportunities'
import { VolunteerOpportunities } from 'screens/main/volunteer-opportunities'
import { OpportunityView } from 'screens/main/volunteer-opportunities/components/OpportunityView'
import { VolunteerCommunityList } from 'screens/main/settings/manage-organization/components/VolunteerOpportunityList'
import CheckInOutQrCode from 'screens/main/volunteer-opportunities/components/CheckInOutQrCode'

const routes = [
  {
    path: '/home',
    exact: true,
    name: 'Home',
    component: Home,
    roles: [config.roles.subscriber],
  },
  {
    path: '/organizations',
    exact: true,
    name: 'Organizations',
    component: OrganizationsList,
    roles: [config.roles.admin],
  },
  {
    path: '/organizations/global-markup',
    exact: true,
    name: 'Organizations',
    component: UpdateGlobalMarkup,
    roles: [config.roles.admin],
  },
  {
    path: '/organizations/organization-markup/:id/:name',
    exact: true,
    name: 'Organizations',
    component: UpdateMarkup,
    roles: [config.roles.admin],
  },
  {
    path: '/organizations/:id',
    exact: true,
    name: 'Organizations',
    component: OrganizationView,
    roles: [config.roles.admin],
  },
  {
    path: '/hardware',
    exact: true,
    name: 'Hardware',
    component: Hardware,
    roles: [config.roles.admin],
  },
  {
    path: '/hardware/add',
    exact: true,
    name: 'Add Hardware',
    component: AddOrEditHardware,
    roles: [config.roles.admin],
  },
  {
    path: '/hardware/add/:organizationId/:hardwareId',
    exact: true,
    name: 'Edit Hardware',
    component: AddOrEditHardware,
    roles: [config.roles.admin],
  },
  {
    path: '/businesses',
    exact: true,
    name: 'Businesses',
    component: BusinessList,
  },
  {
    path: '/businesses/:id',
    exact: true,
    name: 'Businesses',
    component: BusinessView,
  },
  {
    path: '/invitation/join-business-community/:id',
    exact: true,
    name: 'Association Invitation',
    component: AssociationInvitation,
  },
  {
    path: '/events',
    exact: true,
    name: 'Events',
    component: Events,
    roles: [config.roles.subscriber],
  },
  {
    path: '/events/:id',
    exact: true,
    name: 'Events',
    component: EventView,
    roles: [config.roles.subscriber],
  },
  {
    path: '/events/:id/control-screen',
    exact: true,
    name: 'Event Control Screen',
    component: EventControl,
    roles: [config.roles.subscriber],
  },
  // {
  //   path: '/events/:id/join-event',
  //   exact: true,
  //   name: 'Join Event',
  //   component: JoinEvent,
  // },
  {
    path: '/events/auction-item-payment/:id',
    exact: true,
    name: 'Auction Item Payment',
    component: AuctionItemPayment,
  },
  {
    path: '/campaigns',
    exact: true,
    name: 'Campaigns',
    component: Campaigns,
    roles: [config.roles.subscriber],
  },
  {
    path: '/campaigns/:id',
    exact: true,
    name: 'Campaigns',
    component: CampaignView,
    roles: [config.roles.subscriber],
  },
  {
    path: '/campaigns/:id/control-screen',
    exact: true,
    name: 'Campaign Control Screen',
    component: EventControl,
    roles: [config.roles.subscriber],
  },
  // {
  //   path: '/campaigns/:id/join-event',
  //   exact: true,
  //   name: 'Join Campaign',
  //   component: JoinEvent,
  // },
  {
    path: '/fundraisers',
    exact: true,
    name: 'Fundraisers',
    component: Fundraisers,
  },
  {
    path: '/fundraisers/:id',
    exact: true,
    name: 'Fundraisers',
    component: FundraiserView,
  },
  {
    path: '/fundraisers/:id/control-screen',
    exact: true,
    name: 'Fundraisers Control Screen',
    component: FundraiserControl,
  },
  {
    path: '/fundraisers/:id/join-fundraiser',
    exact: true,
    name: 'Join Fundraiser',
    component: JoinFundraiser,
  },
  {
    path: '/programs',
    exact: true,
    name: 'Programs',
    component: Programs,
    roles: [config.roles.subscriber],
  },
  {
    path: '/programs/:id',
    exact: true,
    name: 'Programs',
    component: ProgramView,
    roles: [config.roles.subscriber],
  },
  {
    path: '/services',
    exact: true,
    name: 'Services',
    component: Services,
    roles: [config.roles.subscriber],
  },
  {
    path: '/services/:id',
    exact: true,
    name: 'Services',
    component: ServiceView,
    roles: [config.roles.subscriber],
  },
  {
    path: '/volunteer',
    exact: true,
    name: 'Volunteer',
    component: Volunteer,
    roles: [config.roles.subscriber],
  },
  {
    path: '/volunteer/:id',
    exact: true,
    name: 'Volunteer',
    component: VolunteerView,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/manage-organization/volunteer-communities',
    exact: true,
    name: 'Volunteer Communities',
    component: VolunteerCommunityList,
    roles: [config.roles.subscriber],
  },
  {
    path: '/volunteer-opportunities',
    exact: true,
    name: 'Volunteer Opportunities',
    component: VolunteerOpportunities,
    roles: [config.roles.subscriber],
  },
  {
    path: '/volunteer-opportunities/:id',
    exact: true,
    name: 'Volunteer Opportunities',
    component: OpportunityView,
    roles: [config.roles.subscriber],
  },
  {
    path: '/volunteer-opportunities/:id/qr/:type',
    exact: true,
    name: 'Volunteer Opportunities',
    component: CheckInOutQrCode,
    roles: [config.roles.subscriber],
  },
  {
    path: '/auction',
    exact: true,
    name: 'Auction',
    component: Auction,
    roles: [config.roles.subscriber],
  },
  {
    path: '/auction/:id',
    exact: true,
    name: 'Auction',
    component: AuctionView,
    roles: [config.roles.subscriber],
  },
  {
    path: '/auction/:id/control-screen',
    exact: true,
    name: 'Auction Control Screen',
    component: AuctionControl,
    roles: [config.roles.subscriber],
  },
  {
    path: '/auction/:id/join-auction',
    exact: true,
    name: 'Join Auction',
    component: JoinAuction,
  },
  {
    path: '/rentals',
    exact: true,
    name: 'Rental',
    component: RentalFacilities,
    roles: [config.roles.subscriber],
  },
  {
    path: '/rentals/:id',
    exact: true,
    name: 'RentalReservation',
    component: RentalReservation,
  },
  // donation UI
  {
    path: '/donation-ui/mockup',
    exact: true,
    name: 'donation-ui',
    component: YtdDonationForOrganization,
  },
  // financial reports
  {
    path: '/reports',
    exact: true,
    name: 'Report',
    component: Reports,
  },
  {
    path: '/subscriber-landing-page',
    exact: true,
    name: 'SubscriberLandingPage',
    component: SubscriberLandingPage,
  },
  {
    path: '/reports/ytd-revenue-for-organization',
    exact: true,
    name: 'Report',
    component: YtdRevenueForOrganization,
  },
  {
    path: '/reports/ytd-donation-for-organization',
    exact: true,
    name: 'Report',
    component: YtdDonationForOrganization,
  },
  {
    path: '/reports/ytd-program-service-for-organization',
    exact: true,
    name: 'Report',
    component: YtdProgramAndServiceForOrganization,
  },
  {
    path: '/reports/master-community-report-list',
    exact: true,
    name: 'Report',
    component: MasterCommunity,
  },
  {
    path: '/reports/monthly-revenue-forecast',
    exact: true,
    name: 'Report',
    component: MonthlyRevenueForcast,
  },
  {
    path: '/reports/recurring-donations',
    exact: true,
    name: 'Report',
    component: RecurringDonations,
  },

  {
    path: '/reports/ytd-earnings-report',
    exact: true,
    name: 'Report',
    component: YTDEarningReport,
  },
  {
    path: '/reports/class-registration-report',
    exact: true,
    name: 'Report',
    component: ClassRegistration,
  },
  // component reports
  {
    path: '/reports/ytd-component-reports',
    exact: true,
    name: 'Report',
    component: ComponentPerformanceForOrganization,
  },

  {
    path: '/reports/platform-report',
    exact: true,
    name: 'Report',
    component: Platform,
  },

  {
    path: '/settings/account',
    exact: true,
    name: 'Account',
    component: Account,
  },
  // {
  //   path: '/settings/create-event',
  //   exact: true,
  //   name: 'Create Event',
  //   component: CreateEvent,
  //   roles: [config.roles.subscriber],
  // },
  {
    path: '/settings/create-fundraiser',
    exact: true,
    name: 'Create Fundraiser',
    component: CreateFundraiser,
  },
  {
    path: '/settings/create-fundraiser/:id',
    exact: true,
    name: 'Update Fundraiser',
    component: CreateFundraiser,
  },
  {
    path: '/settings/create-volunteer-opportunities',
    exact: true,
    name: 'Create Volunteer Opportunity',
    component: CreateVolunteerOpportunities,
  },
  {
    path: '/settings/create-volunteer-opportunities/:id',
    exact: true,
    name: 'Update Volunteer Opportunity',
    component: CreateVolunteerOpportunities,
  },
  {
    path: '/settings/create-tap-fundraiser',
    exact: true,
    name: 'Create Tap Click Fundraiser',
    component: TapClickFundraiser,
  },
  {
    path: '/settings/create-tap-fundraiser/:id',
    exact: true,
    name: 'Update Tap Click Fundraiser',
    component: TapClickFundraiser,
  },
  {
    path: '/settings/quick-path-fundraiser',
    exact: true,
    name: 'Create Quick Path Fundraiser',
    component: QuickPathFundraiser,
  },
  {
    path: '/settings/create-room',
    exact: true,
    name: 'Create Room',
    component: CreateRoom,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-room/:id',
    exact: true,
    name: 'Edit Room',
    component: CreateRoom,
    roles: [config.roles.subscriber],
  },
  // {
  //   path: '/settings/create-event/:id',
  //   exact: true,
  //   name: 'Edit Event',
  //   component: CreateEvent,
  //   roles: [config.roles.subscriber],
  // },
  {
    path: '/settings/invites',
    exact: true,
    name: 'Invites',
    component: MyInvites,
  },
  {
    path: '/settings/create-program',
    exact: true,
    name: 'Create A Program',
    component: CreateProgram,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-program/:id',
    exact: true,
    name: 'Create A Program',
    component: CreateProgram,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-service',
    exact: true,
    name: 'Create A Service',
    component: CreateService,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-service/:id',
    exact: true,
    name: 'Create A Servie',
    component: CreateService,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-volunteer',
    exact: true,
    name: 'Create Volunteer Opp',
    component: CreateVolunteer,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-volunteer/:id',
    exact: true,
    name: 'Edit Volunteer',
    component: CreateVolunteer,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-auction',
    exact: true,
    name: 'Create Auction',
    component: CreateAuction,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/create-auction/:id',
    exact: true,
    name: 'Edit Auction',
    component: CreateAuction,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/register-business',
    exact: true,
    name: 'Register Business',
    component: RegisterBusiness,
  },
  {
    path: '/settings/register-business/:id',
    exact: true,
    name: 'Edit Business',
    component: RegisterBusiness,
  },
  {
    path: '/settings/manage-organization/my-businesses-configuration',
    exact: true,
    name: 'Businesses Configuration',
    component: MyBusinessesConfiguration,
  },
  {
    path: '/settings/manage-organization/my-business-service-requests',
    exact: true,
    name: 'My Business Service Requests',
    component: MyBusinessServiceRequests,
  },
  {
    path: '/settings/manage-organization/manage-payments-accounts',
    exact: true,
    name: 'Business Manage Payment Accounts',
    component: BusinessSetupPayments,
  },
  {
    path: '/settings/manage-organization/manage-business-customers',
    exact: true,
    name: 'Business Customers',
    component: MyCustomers,
  },
  {
    path: '/settings/manage-organization/my-community/add-manual-pledge/:id',
    exact: true,
    name: 'My Community',
    component: PledgeModal,
  },
  {
    path: '/settings/manage-organization/manage-business-earnings',
    exact: true,
    name: 'Business Revenue',
    component: MyEarnings,
  },
  {
    path: '/settings/register-organization',
    exact: true,
    name: 'Register Organization',
    component: RegisterOrganization,
  },
  {
    path: '/settings/register-organization/:id',
    exact: true,
    name: 'Edit Organization',
    component: RegisterOrganization,
  },
  {
    path: '/settings/register-organization-v2',
    exact: true,
    name: 'Register Organization',
    component: OrganizationRegistrationV2,
  },
  {
    // path: '/settings/register-organization/:id',
    path: '/settings/update-organization',
    exact: true,
    name: 'Edit Organization',
    component: UpdateOrganization,
  },
  // {
  //   path: '/settings/manage-organization',
  //   exact: true,
  //   name: 'Manage Organization',
  //   component: ManageOrganization,
  // },
  {
    path: '/settings/manage-organization/business-detail',
    exact: true,
    name: 'My Business',
    component: MyBusiness,
  },
  {
    path: '/settings/manage-organization/organization-detail',
    exact: true,
    name: 'My Organization',
    component: MyOrganization,
  },
  {
    path: '/organization/profile/add',
    exact: true,
    name: 'Add Profile',
    component: AddOrEditProfile,
    roles: [config.roles.subscriber],
  },
  {
    path: '/organization/profile/edit/:organizationId/:profileId',
    exact: true,
    name: 'Edit Profile',
    component: AddOrEditProfile,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/manage-organization/my-community',
    exact: true,
    name: 'My Community',
    component: MyDonors,
  },
  {
    path: '/settings/manage-organization/my-reports',
    exact: true,
    name: 'My Reports',
    component: MyReports,
  },
  {
    path: '/settings/manage-organization/my-reports/ytd-earnings-forecast-report',
    exact: true,
    name: 'Ytd Revenue Report',
    component: YtdEarningReport,
  },
  {
    path: '/settings/manage-organization/my-reports/fy-earnings-forecast-report',
    exact: true,
    name: 'FY Revenue Forecast Report',
    component: FYEarningsForecastReport,
  },
  {
    path: '/settings/manage-organization/my-reports/last-year-earnings-report',
    exact: true,
    name: 'My Reports',
    component: LastYearEarningsReport,
  },
  {
    path: '/settings/manage-organization/my-reports/pledge-conversion-reports',
    exact: true,
    name: 'Pledge Conversion Reports',
    component: PledgeConversionReports,
  },
  {
    path: '/settings/manage-organization/my-reports/annual-costs',
    exact: true,
    name: 'Annual Costs',
    component: AnnualCosts,
  },

  {
    path: '/settings/manage-organization/my-reports/master-donor-list-report',
    exact: true,
    name: 'Master Donor List Report',
    component: MasterDonorListReport,
  },
  {
    path: '/settings/manage-organization/my-reports/active-donors-report',
    exact: true,
    name: 'Active Donors Report',
    component: ActiveDonorsReport,
  },
  {
    path: '/settings/manage-organization/my-reports/dormant-donors-report',
    exact: true,
    name: 'Dormant Donors Report',
    component: DormantDonorsReport,
  },
  {
    path: '/settings/manage-organization/my-reports/top-50-donors-report',
    exact: true,
    name: 'Top 50 Donors Report',
    component: Top50DonorsReport,
  },
  {
    path: '/settings/manage-organization/my-reports/ytd-account-donation',
    exact: true,
    name: 'YTD Account Donation',
    component: YTDAccountDonation,
  },

  {
    path: '/settings/manage-organization/my-events',
    exact: true,
    name: 'My Events',
    component: MyEvents,
  },
  {
    path: '/settings/manage-organization/my-rooms',
    exact: true,
    name: 'My Rooms',
    component: MyRooms,
  },
  {
    path: '/settings/manage-organization/my-reservations',
    exact: true,
    name: 'My Reservations',
    component: MyReservations,
  },
  {
    path: '/settings/manage-organization/listing-requests',
    exact: true,
    name: 'Listing Requests',
    component: MyAssociationrequests,
  },
  {
    path: '/settings/contact-us',
    exact: true,
    name: 'Contact us',
    component: ContactUsForm,
  },
  {
    path: '/settings/manage-payments-accounts',
    exact: true,
    name: 'Manage Payment Accounts',
    component: SetupPayments,
  },
  {
    path: '/settings/add-payment-category',
    exact: true,
    name: 'Add Payment Category',
    component: AddPaymentCategory,
  },
  {
    path: '/settings/manage-gov-issued-docs',
    exact: true,
    name: 'Manage Gov Issued Docs',
    component: ManageGovDocs,
  },
  {
    path: '/settings/manage-feedbacks',
    exact: true,
    name: 'Platform Feedback',
    component: PlatformFeedbacks,
  },
  {
    path: '/settings/manage-reservations-requests',
    exact: true,
    name: 'Reservation Requests',
    component: ManageReservationRequests,
  },
  {
    path: '/settings/manage-subscription',
    exact: true,
    name: 'Manage Subscription',
    component: ManageSubscription,
    roles: [config.roles.subscriber],
  },
  {
    path: '/settings/notifications',
    exact: true,
    name: 'Notification',
    component: Notifications,
  },
  {
    path: '/payment/room/:id',
    exact: true,
    name: 'Room Payment',
    component: RentalRoomPayment,
  },
  {
    path: '/:anything',
    // exact: true,
    name: 'Anything',
    component: PageNotFound,
  },
]

export default routes
