import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { Table, Space, Dropdown, Popconfirm, message } from 'antd'
import { searchDonnorRows } from 'utils'
import { EllipsisOutlined } from '@ant-design/icons'
import { makeRequest } from 'api/apiv2'
import FeedbackModal from './FeedbackModal'

const VolunteerList = ({ data, refresh, searchText }) => {
  const [volunteerId, setVolunteerId] = useState(null)
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false)

  const params = useParams()

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      DonorName: `${item.firstName} ${item.lastName}`,
      Email: item.email,
      PhoneNumber: item.phoneNumber,
      Skills: item.skills.join(', '),
      Keyword: item.keyword,
      Feedback: item.feedback,
      key: item.iD,
      iD: item.userId,
    }))

    return formatedData || []
  }

  const removeVolunteer = () => {
    makeRequest(
      'delete',
      `admin/opportunities/${params?.id}/members/${volunteerId}/remove`,
      {},
      {},
    )
      .then(() => {
        message.success('Volunteer removed successfully')
        refresh()
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message ||
            'Error in removing volunteer',
        )
      })
  }

  const inviteForRating = () => {
    const volunteer = data.find((d) => d.userId === volunteerId)
    makeRequest(
      'put',
      `admin/opportunities/${params?.id}/members/${volunteerId}/request-feedback`,
      {},
      {
        phoneNumber: volunteer?.phoneNumber,
      },
    )
      .then(() => {
        message.success('Invite sent successfully')
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message || 'Error in sending invite',
        )
      })
  }

  const items = [
    {
      label: (
        <Popconfirm
          title="Send Invite?"
          onConfirm={inviteForRating}
          okText="Yes"
          cancelText="No"
        >
          <span>Invite to provide rating</span>
        </Popconfirm>
      ),
      key: '0',
    },
    {
      label: (
        <span onClick={() => setIsVisibleFeedback(true)}>
          Add/Edit Feedback
        </span>
      ),
      key: '1',
    },
    {
      label: (
        <Popconfirm
          title="Are you sure you want remove this volunteer?"
          onConfirm={removeVolunteer}
          okText="Yes"
          cancelText="No"
        >
          <span>Remove</span>
        </Popconfirm>
      ),
      key: '2',
    },
  ]

  const columns = [
    {
      title: 'ID',
      dataIndex: 'iD',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'PhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Skills',
      dataIndex: 'Skills',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Keyword',
      dataIndex: 'Keyword',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Feedback',
      dataIndex: 'Feedback',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { iD }) => (
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <Space>
            <EllipsisOutlined
              style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
              onClick={() => setVolunteerId(iD)}
            />
          </Space>
        </Dropdown>
      ),
    },
  ]

  const submitFeedback = (payload) => {
    setIsVisibleFeedback(false)
    makeRequest(
      'put',
      `admin/volunteer-community/${volunteerId}/feedback`,
      {},
      payload,
    )
      .then(() => {
        message.success('Feedback submitted successfully')
        refresh()
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message ||
            'Error in submitting feedback',
        )
      })
  }

  return (
    <>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        columns={columns}
        dataSource={searchDonnorRows(formateData(data), searchText)}
        bordered={false}
        pagination
      />
      <FeedbackModal
        handler={setIsVisibleFeedback}
        isOpen={isVisibleFeedback}
        submit={submitFeedback}
      />
    </>
  )
}

export { VolunteerList }
