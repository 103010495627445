import React, { useState } from 'react'
import { Dropdown, message, Skeleton, Space, Table } from 'antd'
import { searchDonnorRows } from 'utils'
import { EllipsisOutlined } from '@ant-design/icons'
import { makeRequest } from 'api/apiv2'
import SendMessageModal from './SendMessageModal'
import RequestCompleteProfileModal from './RequestCompleteProfileModal'
import FeedbackModal from './FeedbackModal'

const CommunityList = ({
  loading,
  data,
  searchText,
  setSelectedRowKeys,
  selectedRowKeys,
}) => {
  const [sendMessageModal, setSendMessageModal] = useState(false)
  const [isVisibleCompleteProfile, setIsVisibleCompleteProfile] =
    useState(false)
  const [isVisibleFeedback, setIsVisibleFeedback] = useState(false)

  const formateData = (values) => {
    const formatedData = values?.map((item) => ({
      DonorName: `${item.firstName} ${item.lastName}`,
      Email: item.email,
      PhoneNumber: item.phoneNumber,
      MailingAddress: item.mailingAddress,
      key: item.iD,
      iD: item.iD,
    }))

    return formatedData || []
  }

  const items = [
    {
      label: (
        <span onClick={() => setSendMessageModal((prev) => !prev)}>
          Send Message
        </span>
      ),
      key: '0',
    },
    {
      label: (
        <span onClick={() => setIsVisibleCompleteProfile((prev) => !prev)}>
          Request Complete Profile
        </span>
      ),
      key: '1',
    },
    {
      label: (
        <span onClick={() => setIsVisibleFeedback((prev) => !prev)}>
          Add Feedback
        </span>
      ),
      key: '2',
    },
  ]

  const columns = [
    {
      title: 'Name',
      dataIndex: 'DonorName',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Email',
      dataIndex: 'Email',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Phone Number',
      dataIndex: 'PhoneNumber',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Mailing Address',
      dataIndex: 'MailingAddress',
      className: 'ant-table-row-cell-break-word',
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, { iD }) => (
        <Dropdown
          menu={{
            items,
          }}
          trigger={['click']}
        >
          <Space>
            <EllipsisOutlined
              style={{ transform: 'rotate(90deg)', cursor: 'pointer' }}
              onClick={() => setSelectedRowKeys([iD])}
            />
          </Space>
        </Dropdown>
      ),
    },
  ]

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  }

  const sendMessageSubmit = (payload) => {
    const updatedPayload = { ...payload, users: selectedRowKeys }
    setSendMessageModal(false)
    makeRequest(
      'put',
      'admin/volunteer-community/send-message',
      {},
      updatedPayload,
    )
      .then(() => {
        message.success('Message sent successfully')
        setSelectedRowKeys([])
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message || 'Error in sending message',
        )
      })
  }

  const sendCompleteProfile = () => {
    setIsVisibleCompleteProfile(false)
    makeRequest(
      'put',
      `admin/volunteer-community/${selectedRowKeys[0]}/request-complete-profile`,
      {},
      {},
    )
      .then(() => {
        message.success('Message sent successfully')
        setSelectedRowKeys([])
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message || 'Error in sending message',
        )
      })
  }

  const submitFeedback = (payload) => {
    setIsVisibleFeedback(false)
    makeRequest(
      'put',
      `admin/volunteer-community/${selectedRowKeys[0]}/feedback`,
      {},
      payload,
    )
      .then(() => {
        message.success('Feedback submitted successfully')
        setSelectedRowKeys([])
      })
      .catch((error) => {
        console.log('error', error)
        message.error(
          error?.response?.data?.error?.message ||
            'Error in submitting feedback',
        )
      })
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <Table
        tableLayout="auto"
        scroll={{ x: 500 }}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={searchDonnorRows(formateData(data), searchText)}
        bordered={false}
        pagination
      />
      <SendMessageModal
        handler={setSendMessageModal}
        isOpen={sendMessageModal}
        submit={sendMessageSubmit}
      />
      <RequestCompleteProfileModal
        handler={setIsVisibleCompleteProfile}
        isOpen={isVisibleCompleteProfile}
        submit={sendCompleteProfile}
      />
      <FeedbackModal
        handler={setIsVisibleFeedback}
        isOpen={isVisibleFeedback}
        submit={submitFeedback}
      />
    </Skeleton>
  )
}

export { CommunityList }
