import { Button, Col, Divider, Form, Input, Row, Modal } from 'antd'
import React from 'react'
import { volunteerFeedback } from 'validations/volunteerFeedback'

const FeedbackModal = ({ isOpen, handler, submit }) => {
  const [messageForm] = Form.useForm()

  const onFinish = (values) => {
    messageForm.resetFields()
    submit(values)
  }
  return (
    <Modal
      title="Volunteer Feedback"
      visible={isOpen}
      onCancel={() => handler(false)}
      footer={null}
    >
      <Form form={messageForm} layout="vertical" key={0} onFinish={onFinish}>
        <Row className="t-p-1">
          <Col span={24}>
            <Form.Item
              name="feedback"
              label="Add Feedback"
              colon={false}
              rules={[volunteerFeedback]}
            >
              <Input.TextArea
                placeholder="Enter Your Feedback ..."
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
            <p>
              Note: This is internal feedback and will not be shared outside the
              organization or with a volunteer.
            </p>
          </Col>
          <Divider />
          <Col span={24} className="text-right">
            <Button
              type="secondary"
              className="mx-5"
              onClick={() => handler(false)}
            >
              Cancel
            </Button>
            <Button type="primary" className="px-25" htmlType="submit">
              Confirm
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default FeedbackModal
