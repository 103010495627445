import { createSlice } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'

const initialState = {
  loading: false,
  opportunities: [],
  inactiveRecords: [],
  volunteers: [],
}

export const volunteerOpportunitySlice = createSlice({
  name: 'volunteerOpportunity',
  initialState,
  reducers: {
    setLoading: (state, { payload }) => {
      const alteredState = { ...state, loading: payload }
      return alteredState
    },
    setVolunteers: (state, { payload }) => {
      const alteredState = { ...state, volunteers: payload }
      return alteredState
    },
    setOpportunities: (state, { payload }) => {
      const inactiveRecords = payload.filter((item) => item.activeInd === 0)
      const alteredState = {
        ...state,
        opportunities: payload,
        inactiveRecords,
      }
      return alteredState
    },
    reset: () => initialState,
  },
})

export const useVolunteerOpportunitySelector = () =>
  useSelector((state) => state.volunteerOpportunity)
export const volunteerOpportunityActions = volunteerOpportunitySlice.actions
export const volunteerOpportunityReducer = volunteerOpportunitySlice.reducer
