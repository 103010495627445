import React, { useEffect, useState } from 'react'
import { Card, Input, message, Skeleton } from 'antd'
import Api from 'api/apiv2'
import { useAuthSelector } from 'store/authSlice/authReducer'
import { getUserOrganization } from 'store/organizationSlice/organizationActions'
import { SearchOutlined } from '@ant-design/icons'
import { CommunityList } from './components/CommunityList'

const VolunteerCommunityList = () => {
  const { user } = useAuthSelector()
  const [loading, setLoading] = useState(true)
  const [donors, setDonors] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [searchText, setSearchText] = useState(null)

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getUserOrganization(user?.email)
        getVolunteers(response.id)
      } catch (error) {
        console.log('error', error)
        message.error('Something went wrong.')
      }
    }
    getData()
  }, [user])

  const getVolunteers = (id) => {
    Api.get(`/admin/volunteer-community?organizationId=${id}`)
      .then((res) => {
        setDonors(res?.data)
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        message.error('An Error Occured in fetching list')
        setLoading(false)
      })
  }

  return (
    <Skeleton loading={loading} active paragraph={{ rows: 12 }}>
      <div>
        <Card
          className="header-solid"
          bordered={false}
          title={
            <div className="t-flex t-flex-wrap t-justify-between">
              <div>
                <h6 className="font-semibold t-text-2xl">Volunteers</h6>
              </div>
              <div className="t-flex t-items-center t-space-x-2 t-mt-4 sm:t-mt-0">
                <div className="header-control t-mt-0">
                  <Input
                    className="header-search t-w-36"
                    placeholder="Search ..."
                    prefix={<SearchOutlined />}
                    onChange={(e) => {
                      setSearchText(e.target.value)
                    }}
                  />
                </div>
              </div>
            </div>
          }
        >
          <CommunityList
            loading={loading.list}
            data={donors}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
            searchText={searchText}
          />
        </Card>
      </div>
    </Skeleton>
  )
}

export { VolunteerCommunityList }
