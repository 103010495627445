import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  Card,
  Row,
  Col,
  Button,
  Skeleton,
  Divider,
  message,
  Typography,
} from 'antd'
import { getVolunteerOpportunityWithStats } from 'store/volunteerOpportunitySlice/volunteerOpportunityActions'
import { ComponentViewHeader } from 'components/common/componentView/components'
import {
  CalendarOutlined,
  ClockCircleOutlined,
  EnvironmentOutlined,
  HourglassOutlined,
  PrinterOutlined,
} from '@ant-design/icons'
import moment from 'moment'
import sampleImage from 'assets/images/sample/fundraiser-default-image.png'
import { VolunteerList } from './VolunteerList'

const formateDate = (date) => moment(date).format('DD MMMM YY')
const formateTime = (date) => moment(date).format('hh:mm A')

const OpportunityView = () => {
  const history = useHistory()
  const params = useParams()
  const [refresh, setRefresh] = useState(false)
  const { Title, Paragraph } = Typography

  const [opportunityDetail, setOpportunityDetail] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const getData = async () => {
      if (params.id) {
        setLoading(true)
        const record = await getVolunteerOpportunityWithStats(params.id)
        if (record) {
          setOpportunityDetail(record)
          setLoading(false)
        } else {
          message.error('Invalid Opportunity')
          history.push('/settings/create-volunteer-opportunities/')
        }
      }
    }
    getData()
  }, [params.id, history, refresh])

  const printQR = (type) => {
    message.loading('Generating QR code')
    window.open(`/volunteer-opportunities/${params.id}/qr/${type}`, '_blank')
  }

  return (
    <Card
      bordered={false}
      className="header-solid card-product mb-24"
      title={<ComponentViewHeader heading="Volunteer Opportunity Detail" />}
    >
      <Skeleton loading={loading} active paragraph={{ rows: 18 }}>
        {opportunityDetail ? (
          <>
            <Row>
              <Col span={20} lg={10} xl={8} className="col-gallery">
                <img
                  src={
                    opportunityDetail?.bannerImages?.length
                      ? opportunityDetail?.bannerImages?.[0]
                      : sampleImage
                  }
                  className="t-h-40 md:t-h-60 t-w-full t-rounded-lg shadow-3 t-object-cover"
                  alt="Volunteer Opportunity"
                />
              </Col>
              <Col span={24} xl={14} lg={{ offset: 1 }}>
                <div className="t-mb-4">
                  <Title level={1} className="t-text-secondary-100">
                    {opportunityDetail?.title}
                  </Title>
                  <Paragraph className="t-my-4">
                    {opportunityDetail?.description}
                  </Paragraph>
                  <Row className="t-my-4">
                    <Col lg={6} xl={4} md={10}>
                      <EnvironmentOutlined
                        style={{
                          fontSize: '18px',
                          marginRight: '10px',
                        }}
                      />
                      <span style={{ fontSize: '18px' }}>
                        {opportunityDetail?.address || '--'}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 6, offset: 1 }}
                      xl={{ span: 4, offset: 1 }}
                      md={{ span: 8, offset: 1 }}
                    >
                      <CalendarOutlined
                        style={{
                          fontSize: '18px',
                          marginRight: '10px',
                          display: 'inline',
                        }}
                      />
                      <span style={{ fontSize: '18px' }}>
                        {opportunityDetail?.scheduleDate
                          ? formateDate(opportunityDetail?.scheduleDate)
                          : '--'}
                      </span>
                    </Col>
                    <Col
                      lg={{ offset: 1 }}
                      xl={{ offset: 1 }}
                      md={{ offset: 1 }}
                    >
                      <ClockCircleOutlined
                        style={{
                          fontSize: '18px',
                          marginRight: '10px',
                          display: 'inline',
                        }}
                      />
                      <span style={{ fontSize: '18px' }}>
                        {opportunityDetail?.scheduleDate
                          ? formateTime(opportunityDetail?.scheduleDate)
                          : '--'}
                      </span>
                    </Col>

                    <Col
                      lg={{ offset: 1 }}
                      xl={{ offset: 1 }}
                      md={{ offset: 1 }}
                    >
                      <HourglassOutlined
                        style={{
                          fontSize: '18px',
                          marginRight: '10px',
                          display: 'inline',
                        }}
                      />
                      {(opportunityDetail?.volunteerDuration && (
                        <span style={{ fontSize: '18px' }}>
                          {opportunityDetail?.volunteerDuration} Hour(s)
                        </span>
                      )) ||
                        '--'}
                    </Col>
                  </Row>

                  <div className="t-flex t-space-x-2 t-flex-wrap">
                    <Title
                      level={4}
                      style={{ marginTop: '8px', marginRight: '10px' }}
                    >
                      Print QR code:
                    </Title>
                    <Button
                      type="primary"
                      className="t-my-1"
                      style={{ fontSize: '16px' }}
                      onClick={() => printQR('checkin')}
                    >
                      <PrinterOutlined
                        style={{
                          fontSize: '18px',
                          display: 'inline',
                        }}
                      />
                      Check-In
                    </Button>
                    <Button
                      type="primary"
                      className="t-my-1"
                      style={{ fontSize: '16px' }}
                      onClick={() => printQR('checkout')}
                    >
                      <PrinterOutlined
                        style={{
                          fontSize: '18px',
                          display: 'inline',
                        }}
                      />
                      Check-Out
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="t-my-4">
              <Col span={5} className="t-mx-4">
                <Card>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Title level={2}>{opportunityDetail?.noShow}</Title>
                      <p>No-Show</p>
                    </Col>
                    <Col>
                      <div>
                        <CalendarOutlined
                          style={{ fontSize: '24px', color: 'red' }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={5} className="t-mx-4">
                <Card>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Title level={2}>
                        {opportunityDetail?.averageDuration}
                      </Title>
                      <p>Average Duration</p>
                    </Col>
                    <Col>
                      <div>
                        <ClockCircleOutlined
                          style={{ fontSize: '24px', color: '#14B1E0' }}
                        />
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>

            <Card className="registered-volunteers-table">
              <Title className="t-font-bold t-text-base" level={6}>
                Registered Volunteers
              </Title>
              <Divider />
              <VolunteerList
                data={opportunityDetail?.opportunityMembers}
                refresh={() => setRefresh((prev) => !prev)}
              />
            </Card>
          </>
        ) : (
          <div className="ant-result ant-result-404">
            <p>Error loading data</p>
          </div>
        )}
      </Skeleton>
    </Card>
  )
}

export { OpportunityView }
