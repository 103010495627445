import * as Yup from 'yup'

const schema = Yup.object().shape({
  feedback: Yup.string().required('Feedback is required'),
})

const volunteerFeedback = {
  async validator({ field }, value) {
    await schema.validateSyncAt(field, { [field]: value })
  },
}

export { volunteerFeedback }
