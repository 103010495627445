import React from 'react'
import { Button, Col, Divider, Form, Row, Modal } from 'antd'

const RequestCompleteProfileModal = ({ isOpen, handler, submit }) => {
  const [messageForm] = Form.useForm()

  const onFinish = () => {
    submit()
  }
  return (
    <Modal
      title="Request Complete Profile"
      visible={isOpen}
      onCancel={() => handler(false)}
      footer={null}
    >
      <Form form={messageForm} layout="vertical" key={0} onFinish={onFinish}>
        <Row className="t-p-1">
          <Col span={24}>
            <p>
              Are you sure you want to send volunteer a notification to complete
              his/her profile?
            </p>
          </Col>
          <Divider />
          <Col span={24} className="text-right">
            <Button
              type="secondary"
              className="mx-5"
              onClick={() => handler(false)}
            >
              No
            </Button>
            <Button type="primary" className="px-25" htmlType="submit">
              Yes
            </Button>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export default RequestCompleteProfileModal
